.footer {
  padding-left: 20px;
  padding-right: 20px;
  overflow: auto;
  display: flex;
  align-items: center;
  background-color: #0e3749;
  justify-content: space-between;
}
.footer-icon {
  fill: white;
  cursor: pointer;
}

.foot-text {
  color: white;
  font-family: Poppins;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400px;
  margin: 10px;
}

.footer-icon {
  height: 26px;
  width: 30px;
  margin: 10px;
}

.footer-right {
  display: flex;
  justify-content: flex-end;
}

.footer-center {
  text-align: center;
}

.footer-left {
  text-align: center;
}

.footer-left a {
  text-decoration: none;
  color: white;
}

/* @media (orientation: portrait) {
  .footer {
    display: none;
  }
} */
@media only screen and (max-width: 948px) {
  .footer {
    margin-top: calc(100px - 50 / 1512 * 100px);
  }
}
@media only screen and (max-width: 754px) {
  .footer {
    flex-direction: column;
  }
  .footer-left {
    display: none;
  }
  .IvyLogo {
    width: 120px !important;
    height: 20px !important;
  }
  .SincLogo {
    width: 30px !important;
    height: 30px !important;
  }
}
@media only screen and (max-width: 748px) {
  .footer {
    margin-top: 20px;
    padding-top: 20px;
  }
}
