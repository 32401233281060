.aboutUs {
  position: relative;
  padding: 100px 50px 0 50px;
}

.aboutUsHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 75px;
  text-align: center;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutUsHeadingUl {
  margin-top: 30px;
  margin-bottom: 60px;
  width: 100px;
}

.aboutUsContents {
  display: flex;
  align-items: center;
}

.aboutUsIllustration {
  flex: 1;
  text-align: center;
}

.aboutUsTextbox {
  flex: 1;
  border-radius: 15px;
  background-color: #ffffff;
  margin-left: 30px;
  padding: 35px;
  height: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  /* line-height: 36px; */
  text-align: justify;
}

.aboutUsTextbox2 {
  border-radius: 15px;
  background-color: #ffffff;
  margin-left: 30px;
  padding: 35px;
  height: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  /* line-height: 36px; */
  text-align: justify;
  margin-top: 80px;
}

@media only screen and (max-width: 1280px) {
  .aboutUsTextbox,
  .aboutUsTextbox2 {
    font-size: 20px;
    padding: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .aboutUsHeadingUl {
    margin-top: 20px;
    width: 80px;
  }

  .aboutUsHeading {
    font-size: 40px;
  }

  .aboutUsIllustration img {
    width: 100%;
  }

  .aboutUsTextbox,
  .aboutUsTextbox2 {
    padding: 30px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .aboutUs {
    padding: 80px 30px 0 30px;
  }

  .aboutUsHeading {
    font-size: 30px;
  }

  .aboutUsHeadingUl {
    width: 40px;
    height: 1.25px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .aboutUsIllustration {
    display: none;
  }

  .aboutUsTextbox {
    padding: 20px 20px 0 20px;
    font-size: 15px;
    margin-left: 0;
  }

  .aboutUsTextbox2 {
    padding: 20px 20px 20px 20px;
    font-size: 15px;
    margin-left: 0;
    max-height: 1000px;
  }
}
