.navbar {
    background-color: rgba(0,0,0,0);
    padding: 0.75rem;
    margin-left: auto;
}

.navbar-scrolled{
    background-color: #0E3749 !important;
}

.navbar-nav {
    margin-left: auto;
}

.header {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.nav-item {
    margin: 15px;
    z-index: 0;
}

.nav-link {
    color: white;
    font-family: Poppins;
    font-size: 19px;
    line-height: 15px;
    font-weight: 300;
    position: relative;
}

.nav-link:hover, .nav-link:focus{
    color: #ffffff;
}

.nav-link-active{
    font-weight: 500;
    font-size: 21px;
}


@media only screen and (min-width: 992px) {
/* .nav-link:hover{
    color: black;
    font-weight: 500;
} */

.nav-link::before {
    content: '';
    position: absolute;
    width: 100%;
    /* height: 100%; */
    height: 3px;
    background-color: #5cc6cf;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .2s ease-in-out;
    z-index: -1;
}

.nav-link:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}

.nav-link-active::before {
    transform-origin: left;
    transform: scaleX(1) !important;
}
}


.vertical-line {
    border-right: 1px solid #ffffff;
    margin-left: 15px;
    margin-right: 15px;
}

.navbar-brand {
    font-family: Poppins;
    font-weight: 500;
    color: white;
    font-size: 27px;
    margin-left: 20px;
}

.navbar-brand:hover {
    color: white;
}

.navbar-item {
    margin-left: auto;
    margin-right: 40px;
}

@media only screen and (max-width: 1166px) {
    .nav-item {
        margin: 0px;
    }
}

@media only screen and (max-width: 1024px) {
    
    .nav-item {
        margin: 0px;
    }

    .navbar-brand {
        font-size: 25px;
    }
}

@media only screen and (max-width: 992px) {
    .header{
        background-color: #0E3749;
    }
    .nav-item {
        padding: 5px 20px;
        text-align: center;
        border-top: rgb(164, 164, 164) 1px solid;
    }
}

@media only screen and (max-width: 768px) {
    .navbar {
        padding: .5rem;
    }

    .navbar-brand {
        font-size: 25px;
        justify-content: center;
    }
}

@media only screen and (max-width: 470px) {
    .navbar {
        padding: .25rem;
    }

    .navbar-brand {
        font-size: 17px;
        justify-content: center;
    }
}

@media only screen and (max-width: 400px) {
    .navbar-brand {
        font-size: 15px;
        justify-content: center;
    }
}