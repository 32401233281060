.expoHero{
    background-image: url(./hero-hexagon-pattern.svg);
    background-position: top 100px left;
    background-repeat: no-repeat;
    background-size: auto 80%;
    min-height: 90vh;
    padding-bottom: 50px;
}

.expoHeroContents{
    font-family: 'Poppins';
    font-style: normal;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 5vw;
}

.expoHeroHeading1{
    font-weight: 600;
    font-size: 35px;
    line-height: 75px;
    margin: auto;
    width: fit-content;
}

.expoHeroHeading2{
    font-weight: 400;
    font-size: 30px;
    line-height: 60px;
    width: fit-content;
}

.expoHeroHeading3{
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;
    width: fit-content;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}


@media only screen and (max-width: 1280px) {
.expoHeroHeading1{
    font-size: 30px;
    line-height: 60px;
}

.expoHeroHeading2{
    font-size: 25px;
}

.expoHeroHeading3{
    font-size: 55px;
}
}

@media only screen and (max-width: 1024px) {
.expoHeroHeading1{
    font-size: 25px;
    line-height: 60px;
}

.expoHeroHeading2{
    font-size: 25px;
}

.expoHeroHeading3{
    font-size: 50px;
}
}

@media only screen and (max-width: 768px) {
.expoHero{
    background-image: url(./hero-hexagon-pattern-mobile.svg);
    background-size: cover;
}

.expoHeroHeading1{
    font-size: 25px;
    line-height: 50px;
}

.expoHeroHeading2{
    font-size: 20px;
    line-height: 40px;
}

.expoHeroHeading3{
    font-size: 45px;
    line-height: 60px;
}
}

@media (orientation: portrait) {
.expoHero{
    background-image: url(./hero-hexagon-pattern-mobile.svg);
    background-size: cover, contain;
    background-position: bottom center, top left;
}
}