.events{
    position: relative;
    padding-top: 50px;
    /* padding-bottom: 50px; */
    font-family: 'Poppins';
    font-style: normal;
}

.eventsHeading{
    font-weight: 400;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventsHeadingUl{
    margin-top: 30px;
    margin-bottom: 60px;
    width: 100px;
}

.swiper{
    height: fit-content;
    max-width: 1440px;
    /* position: absolute; */
}

.carouselCard{
    margin-bottom: 50px;
    transition: all .2s ease;
}

.carouselCardInactive{
    transform: scale(0.85);
    cursor: pointer;
    /* decrease brightness */
    filter: brightness(0.75);
}

.card-title{
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.eventImg {
    position: relative;
    overflow: hidden;
}
.eventImg:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
  
.eventImg img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.card-title{
    font-size: 22px;
}

.eventDate{
    font-size: 14px;
}

.eventDateIcon{
    width: 15px;
}

.swiper-button-next, .swiper-button-prev{
    background-color: rgba(0,0,0,0);
    filter: drop-shadow(0 0 3px rgb(0 0 0 / 1));
    padding: 20px;
    border-radius: 10px;
    height: 100%;
    transform: translateY(-50%) scale(1.2);
    color: white;
}

.swiper-button-next{
    right: 0;
}
.swiper-button-prev{
    left: 0;
}

.swiper-pagination-bullet-active{
    background: white;
}

@media only screen and (max-width: 1024px) {
.eventsHeadingUl{
    margin-top: 20px;
    width: 80px;
}

.eventsHeading{
    font-size: 40px;
}
}

@media only screen and (max-width: 768px) {
.eventsHeading{
    font-size: 30px;
}

.eventsHeadingUl{
    width: 40px;
    height: 1.25px;
    margin-top: 0;
    margin-bottom: 50px;
}
}

@media (orientation: portrait){
.swiper-button-next, .swiper-button-prev{
    display: none;
}
}