.eventspage {
  display: flex;
  height: 91.28vh;
  padding-top: 11vh;
}
.eventsLeft {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eventsRight {
  flex: 0.5;
  overflow-y: scroll;
  padding-left: 5vw;
}
.scrollContainer {
  overflow-y: scroll;
}
div::-webkit-scrollbar {
  width: 3px;
}
div::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 1px;
}
div::-webkit-scrollbar-thumb {
  background: #0e1d51;
  border-radius: 1px;
  height: 2px;
}

.upArrow,
.downArrow {
  color: #ffffff;
  margin-left: -150px;
}
.upArrow > span {
  font-size: 22px;
  font-weight: 600;
}
.downArrow > span {
  font-size: 22px;
  font-weight: 600;
}
.upArrow:hover,
.downArrow:hover {
  color: #bde3ff;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .eventsLeft {
    flex: 1;
  }
  .eventsRight {
    flex: 0;
  }
}
